import React from 'react'
import Layout from '../components/layout'
import aboutUsPic from '../images/about/about-us.png'
const AboutUs = () => (
  <Layout>
    <p>
      <img src={aboutUsPic} alt="About Us" />
    </p>
    <h1>
      <strong>INTRODUCTION!</strong>
    </h1>
    <p>
      <strong>DUATO's</strong> adventure started in 2005 when I (Isabel Alonso)
      first traveled to China to look for goods to export to the rest of the
      world.
    </p>
    <p>
      From the first moment here my eyes were wide opened and I was impressed by
      the extraordinary production possibilities, even though I suffered from
      big communication challenges and quality level differences. I realized
      that the only way to ensure that everything could follow high quality
      standards was permanently moving to China so I could control by myself
      every step of the path: from the order to the delivery through quality
      controls during production as well as cargo inspections.
    </p>

    <p>
      Meanwhile, other foreign companies started requiring our help to import in
      their own countries: “they wanted me to solve their problems and make
      business easier for them”. <strong>DUATO</strong> becomes a company with a
      team of professionals with a technical office based in Foshan, situated
      near Canton, the main Industrial area \"in China to provide better
      solutions for importers worldwide, connecting them with factories all over
      China.
    </p>

    <p>
      After time passed we start to be affected per the antidumping laws from
      other countries against China and that situation make us move to India, so
      we also started some years ago to help our clients to move to India and
      make the quality control in that new country.
    </p>

    <p>
      Isabel Alonso, <strong>DUATO's</strong> Founder &#x26; CEO
    </p>

    <h1>
      <strong>MISSION</strong>
    </h1>

    <p>
      <strong>DUATO's</strong> mission is to be your eyes in China &#x26; India
      - protecting your interests from suppliers’ research - product
      development, production, quality control, cargo inspection, export/import
      - to product delivery, acting as your own delegation here.
    </p>

    <p>
      Here below are some of the main reasons why choosing
      <strong>DUATO</strong> will make a difference for you:
    </p>

    <ul>
      <li>
        <p>
          Find out the suppliers and products according to your requirements.
        </p>
      </li>

      <li>
        <p>Enjoy a better communication and shorter delay.</p>
      </li>

      <li>
        <p>Reduce business development limits.</p>
      </li>

      <li>
        <p>
          Get consulting service from our experts in production, imports and
          marketing.
        </p>
      </li>

      <li>
        <p>Decrease your cost.</p>
      </li>

      <li>
        <p>Enhance your differentiation.</p>
      </li>

      <li>
        <p>Know the latest products and brands.</p>
      </li>

      <li>
        <p>
          Feel in security due to our confidentiality and legal consultancy
          service.
        </p>
      </li>

      <li>
        <p>
          Be able to better compete in your market: “buy better to sell better”.
        </p>
      </li>
    </ul>

    <h1>
      <strong>SERVICES</strong>
    </h1>

    <p>
      Located in the main industrial area, <strong>DUATO</strong> connects you
      with Chinese and Indian factories and helps you to choose the right
      selection of a supplier for your imports, according to your requirements.
    </p>

    <p>
      <strong>DUATO's</strong> procedures are implemented according to with the
      western business requirements and international standards; our technical
      team – European engineers - has a demonstrated strong experience and
      know-how in production, labs, R&#x26;D, market research, marketing, and
      international trading.
    </p>

    <p>
      <strong>DUATO</strong> is specialized in products and materials from
      different industries such as BUILDING MATERIALS (ceramic, stone, aluminum,
      glass, wood, etc.); CUSTOMIZED PRODUCTS (exclusive models production in
      ceramic tiles, stone, mosaic); CHEMICAL INDUSTRY PRODUCTS; HARDWARE; HOTEL
      MATERIALS AND PRODUCTS; MERCHANDISING AND GIFTS; PRINTING; DISPLAY.... In
      case you would like to know more about these products or many others just
      contact us.
    </p>

    <p>
      <strong>DUATO</strong> will support you at each stage of the acquisition
      of a product or material: 1 - Sourcing, audits and Production 2 - Quality
      Control &#x26; Cargo Inspection 3 - Import/Export &#x26; Logistics 4 -
      Client’s Representative office in China
    </p>

    <h1>
      1. <em>SOURCING, AUDITS &#x26; FOLLOWING UP THE PRODUCTION</em>.
    </h1>

    <p>
      <em>PRODUCT AND SUPPLIER RESEARCH</em>
    </p>

    <ul>
      <li>
        Product and suppliers research according to the client’s technical
        specifications, quality and target cost.
      </li>

      <li>Market study-factories visit and technical audit.</li>

      <li>Contract negotiation: production, delivery, price and terms.</li>

      <li>Production / sourcing</li>
    </ul>

    <p>
      <em>PRODUCT DEVELOPMENT &#x26; INDUSTRIAL DESIGN</em>
    </p>

    <ul>
      <li>
        Product design and development according to technical specifications and
        drawing.
      </li>

      <li>Samples development and approval</li>
    </ul>

    <p>
      <em>ENGINEERING</em>
    </p>

    <ul>
      <li>Research and development.</li>

      <li>Technological assistance.</li>

      <li>Production set up.</li>

      <li>Productivity improvement.</li>
    </ul>

    <h1>
      2. <em>QUALITY CONTROL (CERTIFIED COMPANY) &#x26; CARGO INSPECTION.</em>
    </h1>

    <ul>
      <li>
        <p>
          To warranty the correct quality of the goods – test and analysis
          directly in the factory - according to the client’s contractual
          specifications.
        </p>
      </li>

      <li>
        <p>Items/models, colors, size checking.</p>
      </li>

      <li>
        <p>Quality control – analysis and technical tests.</p>
      </li>

      <li>
        <p>To warranty that the shipped goods match with the original order.</p>
      </li>

      <li>
        <p>Product accounting according to proforma invoice/original order.</p>
      </li>

      <li>
        <p>
          Packaging checking, Packing list verification Loading Technical
          Report.
        </p>

        <p>
          <strong>DUATO</strong> QUALITY CONTROL CERTIFICATE Our controls fit
          with an exhaustive technical process. After passing our tests and
          inspection,
          <strong>DUATO</strong> issues the QCC - QUALITY CONTROL CERTIFICATE to
          authorize the loading.
        </p>

        <p>
          Including the QCC in your Letter of Credit or original purchase order,
          you get the certification that goods have been inspected and found
          conformed to the contractual specifications. The QCC is completed by
          our technical report, including pictures to evidence the correct
          shipment of the goods.
        </p>
      </li>
    </ul>

    <h1>
      3. <em>IMPORT / EXPORT AND LOGISTICS.</em>
    </h1>

    <p>
      Our aim is to speed up the import process, communication with factories,
      bank and logistic operators. Our export department will support all the
      process from the order to the delivery at destination. We take care about
      your orders as ours acting as your own delegation in China.
    </p>

    <ul>
      <li>
        Proforma invoice negotiation, checking and approval-coordination with
        factories.
      </li>

      <li>
        Shipment arrangement and freight quotation – Logistics consultancy.
      </li>

      <li>L/C opening, checking and validation – Banking consultancy.</li>

      <li>Import documents arrangement according to customs .destination</li>
    </ul>

    <h1>
      4. <em>CLIENT’S REPRESENTATIVE OFFICE for IMPORT &#x26; EXPORT.</em>
    </h1>

    <p>
      Our experience in China market allow us to introduce and start up the
      office for our client business fast and easy, we could organize all
      related to the office management in China.
    </p>

    <ul>
      <li>
        <p>Office administration (pay on behalf of our client)</p>

        <ul>
          <li>Look for a good situation office.</li>

          <li>Pay the rent, WI-FI and expenses on behalf of the client.</li>

          <li>Make client’s decoration office according to their needs.</li>
        </ul>
      </li>

      <li>Human Resource (Hire employees on behalf of our client).</li>

      <li>Accounting management of Chinese and Indian office.</li>

      <li>
        <p>Warehouse service and management:</p>

        <ul>
          <li>Samples management</li>

          <li>Standards for each production and products</li>

          <li>Collection and redistribution of partial orders</li>
        </ul>
      </li>
    </ul>
  </Layout>
)
export default AboutUs